<template>
  <div class="signIn" v-title="'报名'">
    <div class="form">
      <div class="form-item">
        <input class="input" type="text" v-model="name" placeholder="姓名">
      </div>
      <div class="form-item">
        <input class="input" type="text" v-model="job" placeholder="职业">
      </div>
      <div class="form-item">
        <input class="input" type="tel" v-model="phone" placeholder="手机">
      </div>
      <button class="btn-submit" @click="submit">立即报名</button>
    </div>
    <div class="clearfix">
      <img class="fr wx-qrcpde" src="static/h5/siemens/wx-qrcode.png" alt="">
    </div>
    <div class="foot">
      <img class="" src="static/h5/siemens/footer.png" alt="">
    </div>
  </div>
</template>
<script>
import validate from '@/common/validate.js';
import {
  SignUp
} from "@/common/api.js";
export default {
  data(){
    return{
      name:'',
      job:'',
      phone:'',
      cityData:{}
    }
  },
  methods: {
    //提交
    submit(){
      if (this.job.trim() == '') {
          this.$toast('请输入姓名');
          return false;
      }
      if (this.job.trim() == '') {
          this.$toast('请输入职业');
          return false;
      }
      if (this.phone.trim() == '') {
          this.$toast('请输入手机号');
          return false;
      }
      if (!validate.phone(this.phone)) {
          this.$toast('手机号格式不正确');
          return false;
      }
      let params={
        Name:this.name,
        Phone:this.phone,
        Profession:this.job,
        SignType:1,
        ...this.$route.query
      };
      if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          params['ClientType'] = 2;
      } else {
          params['ClientType'] = 1;
      }
      SignUp(params).then(r=>{
        this.$toast(r.Message);
      })
    }
  },
}
</script>
<style lang="scss">
.signIn{
  max-width: 10rem;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;
  .form{
    width: 8rem;
    margin: 0.88rem auto 0.853333rem;
    .form-item{
      width: 100%;
      margin-bottom: 20px;
    }
    .input{
      height: 40px;
      border: 1px solid #999;
      background-color: transparent;
      color: #333;
      font-size: 16px;
      padding: 8px 12px;
      line-height: 22px;
      display: block;
      width: 100%;
    }
    .btn-submit{
      display: block;
      width: 100%;
      height: 40px;
      background-color: #FF7E16;
      font-size: 16px;
      color: #fff;
      line-height: 40px;
    }
  }
  .wx-qrcpde{
    width: 9.2rem;
  }
  .foot{
    margin-top: 0.56rem;
    img{
      display: block;
      margin: 0 auto;
      width: 8.866667rem;
    }
  }
}
</style>